@import "../shared-components/styles/sizes";

.main-layout {
  height: 100vh;

  .main-page {
    margin-bottom: -$footerHeight;
    display: flex;
    flex-direction: column;

    &.main-page-not-responsive {
      min-height: calc(100% - #{$menuTopHeight});
    }

    &.main-page-responsive {
      min-height: 100%;
    }

    .menu-container {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1000;
    }

    .main-content {
      background-color: white;
      display: flex;
      flex-grow: 2;
      width: 100%;
    }
  }

  .main-page:after {
    content: "";
    display: block;
    height: $footerHeight;
  }
}

// Add an ugly border
// Useful to distinct environments
.main-layout-bordered {
  padding: 1rem;
  background: url("../shared-components/img/qa_border.png");
}
